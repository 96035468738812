import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TextField from '../../components/form/TextField';
import ColumnLayout from '../../components/layout/ColumnLayout';
import Grid from '../../components/layout/Grid';
import Inline from '../../components/layout/Inline';
import Button from '../../components/form/Button';
import AlertBar from '../../components/form/AlertBar';
import Table, { Cell, HeaderRow, Row } from '../../components/table/Table';
import PlaqueOrder from '../../fragments/PlaqueOrder';
import { joinDefined } from '../../utils/strings';
import StaffAutoComplete from '../../components/form/StaffAutoComplete';
import CommunicationNoteModal from '../CommunicationNotes/CommunicationNoteModal';
import FileUpload, { getFileName } from '../../components/form/FileUpload';
import DocumentModal from '../Cremation/Modals/DocumentModal';
import AlertModal from '../../components/modal/AlertModal';
import { DeleteIcon } from '../../components/IconIndex';
import { getUser } from '../../utils/sessions';

class CorrespondenceTab extends Component {
    state = {
        showConfirmComplete: false,
        documentModal: {
            open: false,
            document: null
        }
    };

    render() {
        const { documentModal, showConfirmComplete } = this.state;
        const { form } = this.props;

        return (
            <Fragment>
                <ColumnLayout>
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                </ColumnLayout>

                <DocumentModal
                    onClose={() => this.setState({ documentModal: { open: false, document: null } })}
                    open={!!documentModal.open}
                    form={form}
                    document={documentModal.document}
                />

                <AlertModal
                    variant="save"
                    open={showConfirmComplete}
                    title="Prompt"
                    primaryAction="Yes, Mark as Complete"
                    onClickPrimaryAction={() => this.handleMarkOrderAsComplete()}
                    secondaryAction="Actually, not just yet"
                    onClickSecondaryAction={() => this.setState({ showConfirmComplete: false })}
                    onClose={() => this.setState({ showConfirmComplete: false })}
                >
                    <p>You're about to mark this Plaque Order as complete.</p>
                    <p>Are you sure?</p>
                </AlertModal>
            </Fragment>
        );
    }

    renderRightColumn() {
        return (
            <Fragment>
                {this.renderCompletion()}
                {this.renderSignficantDates()}
            </Fragment>
        );
    }

    renderCompletion() {
        const { form } = this.props;
        const datesDone = !!form.getField('AccountPaidDate') && !!form.getField('PlaqueOrAshesPlacedOrDeliveredDate');
        const isCompleted = !!form.getField('Complete');
        return (<Grid container bucket>
            <Grid item>
                <p>
                    A Plaque Order can be marked as complete when the account is paid and plaque/ashes are
                    delivered.
                </p>
            </Grid>
            <Grid item>
                {(!isCompleted && (
                    <Button
                        disabled={form.isDirty || !datesDone}
                        variant="urgent"
                        onClick={() => this.setState({ showConfirmComplete: true })}
                    >
                        Mark Order as Complete...
                    </Button>
                )) || <AlertBar variant="success">Order is marked as complete.</AlertBar>}
            </Grid>
        </Grid>);
    }

    renderSignficantDates() {
        const { form } = this.props;
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Significant Dates</h4>
                </Grid>

                <Grid item>
                    <TextField label="Quote Signed" type="date" form={form} name="QuoteSignedDate" />
                </Grid>

                <Grid item>
                    <TextField
                        label="Ashes Application Form Signed and Faxed"
                        type="date"
                        form={form}
                        name="AshesApplicationFormSignedDate"
                    />
                </Grid>

                <Grid item>
                    <TextField label="Plaque Wording Sent" type="date" form={form} name="PlaqueWordingSentDate" />
                </Grid>

                <Grid item>
                    <TextField
                        label="Proof arrived and applicant notified"
                        type="date"
                        form={form}
                        name="ProofArrivedDate"
                    />
                </Grid>

                <Grid item>
                    <TextField
                        label="Approval Sent to Supplier"
                        type="date"
                        form={form}
                        name="ApprovalSentToSupplierDate"
                    />
                </Grid>

                <Grid item>
                    <TextField label="Invoice Created & Posted" type="date" form={form} name="InvoiceCreatedDate" />
                </Grid>

                <Grid item>
                    <TextField label="Plaque Arrived" type="date" form={form} name="PlaqueArrivedDate" />
                </Grid>

                <Grid item>
                    <TextField label="Account Paid" type="date" form={form} name="AccountPaidDate" />
                </Grid>

                <Grid item>
                    <TextField
                        label="Plaque / Ashes Placed or Deivered to Council"
                        type="date"
                        form={form}
                        name="PlaqueOrAshesPlacedOrDeliveredDate"
                    />
                </Grid>

                <Grid item>
                    <TextField label="Niche Confirmed" type="date" form={form} name="NicheConfirmedDate" />
                    {!form.getField('NicheConfirmedDate') && (
                        <Inline>
                            <AlertBar variant="warning">Warning, niche has not been confirmed</AlertBar>
                        </Inline>
                    )}
                </Grid>
            </Grid>
        );
    }

    renderLeftColumn() {
        return (
            <Fragment>
                {this.renderAssignStaff()}
                {this.renderCommunicationNotes()}
                {this.renderSignificantDocuments()}
            </Fragment>
        );
    }

    renderCommunicationNotes() {
        const { form } = this.props;
        const user = getUser();
        return <CommunicationNoteModal
            orderID={form.getField('ID')}
            orderKey={form.getField('LegacyKey')}
            orderType="PlaqueOrder"
            orderClient={form.getField('Client')}
            orderAuthorisedContacts={form.getField('AuthorisedContacts')}
            substitutionFields={{
                '[[Client.FirstName]]': form.getField('Client.FirstName'),
                '[[Client.Surname]]': form.getField('Client.Surname'),
                '[[Deceased.FirstName]]': form.getField('Deceased.FirstName'),
                '[[Deceased.Surname]]': form.getField('Deceased.Surname'),
                '[[Reference]]': form.getField('LegacyKey'),
                '[[StaffName]]': user.FirstName + ' ' + user.Surname
            }}
        />;
    }

    renderAssignStaff() {
        const { form } = this.props;
        const staffMembers = form.getField('StaffMembers') || [];
        let showExistingStaffMembers = false;

        staffMembers.forEach(function(staffMember) {
            if (!staffMember.new) {
                showExistingStaffMembers = true;
            }
        });

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Assign Staff Member</h4>
                </Grid>

                {staffMembers.map((staffMember, index) => this.renderAddStaffMember(staffMember, index))}

                <Grid item>
                    <Button variant="primary" onClick={() => this.handleAddStaffMember()}>
                        + Assign Staff
                    </Button>
                </Grid>

                {showExistingStaffMembers && (
                    <Grid item>
                        <Table>
                            <HeaderRow pad>
                                <Cell colSpan={1}>Name</Cell>
                                <Cell colSpan={1}>Role</Cell>
                                <Cell colSpan={1}>Allocation</Cell>
                                <Cell colSpan={1}>Remove</Cell>
                            </HeaderRow>

                            {staffMembers.map((staffMember, index) => this.renderStaffMember(staffMember, index))}
                        </Table>
                    </Grid>
                )}
            </Grid>
        );
    }

    renderAddStaffMember(staffMember, index) {
        const { form } = this.props;

        if (!staffMember.new) {
            return;
        }

        return (
            <Fragment key={index}>
                <Grid item>
                    <StaffAutoComplete
                        label="Staff Name"
                        placeholder="Search for staff name"
                        selectProps={{ multiple: false }}
                        onSelect={(_, user) => this.handleSelectStaff(`StaffMembers[${index}].Staff`, user)}
                        form={form}
                        name={`StaffMembers[${index}].Staff`}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Role" form={form} name={`StaffMembers[${index}].Role`} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Allocation" form={form} name={`StaffMembers[${index}].Allocation`} />
                </Grid>
            </Fragment>
        );
    }

    renderStaffMember(staffMember, index) {
        if (!!staffMember.new) {
            return;
        }

        let staffMemberName = '';

        if (!!staffMember.Staff) {
            staffMemberName = joinDefined([staffMember.Staff.FirstName, staffMember.Staff.Surname], ' ');
        }

        return (
            <Row pad key={index}>
                <Cell dataLabel="Name">{staffMemberName}</Cell>
                <Cell dataLabel="Role">{staffMember.Role}</Cell>
                <Cell dataLabel="Allocation">{staffMember.Allocation}</Cell>
                <Cell dataLabel="Remove">
                    <IconButton
                        className="icon"
                        title={'Delete'}
                        onClick={() => this.handleDeleteStaffMember(staffMember, index)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Cell>
            </Row>
        );
    }

    renderSignificantDocuments() {
        const { form } = this.props;
        const significantDocuments = form.getState('SignificantDocuments') || [];

        let showExistingDocuments = significantDocuments.length > 0;

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Significant Documents</h4>
                </Grid>

                {showExistingDocuments && (
                    <Grid item>
                        <Table>
                            <HeaderRow pad>
                                <Cell colSpan={1}>ID</Cell>
                                <Cell colSpan={1}>Filename</Cell>
                                <Cell colSpan={1}>Size</Cell>
                            </HeaderRow>

                            {significantDocuments.map((significantDocument, index) =>
                                this.renderSignificantDocument(significantDocument, index)
                            )}
                        </Table>
                    </Grid>
                )}

                <Grid item>
                    <Inline>
                        <FileUpload
                            label={'Upload Documents'}
                            folderPath={`/documents/plaque/${form.getField('ID')}`}
                            onComplete={({ uploadFile }) => this.handleUploadSignificantDocument(uploadFile)}
                        />
                    </Inline>
                </Grid>
            </Grid>
        );
    }

    renderSignificantDocument(significantDocument, index) {
        return (
            <Row pad key={index}>
                <Cell dataLabel="ID">
                    <Button
                        variant="link-orange"
                        href={significantDocument.AbsoluteLink}
                        onClick={e => {
                            e.preventDefault();
                            this.setState({
                                documentModal: { open: true, document: significantDocument }
                            });
                        }}
                    >
                        #{significantDocument.ID}
                    </Button>
                </Cell>
                <Cell dataLabel="Filename">{significantDocument.Name}</Cell>
                <Cell dataLabel="Size">{significantDocument.Size}</Cell>
            </Row>
        );
    }

    handleUploadSignificantDocument(uploadFile) {
        const { form } = this.props;

        const SignificantDocuments = form.getField('SignificantDocuments') || [];

        SignificantDocuments.push({
            ID: uploadFile.ID,
            AbsoluteLink: uploadFile.AbsoluteLink,
            Name: getFileName(uploadFile.FileName),
            Size: 'Save to confirm'
        });

        form.setField({ SignificantDocuments });
    }

    handleAddStaffMember() {
        const { form } = this.props;
        const StaffMembers = form.getState('StaffMembers') || [];

        StaffMembers.push({
            new: true
        });
        form.setState({ StaffMembers });
    }

    handleDeleteStaffMember(staffMember, index) {
        const { form } = this.props;
        const StaffMembers = form.getState('StaffMembers') || [];

        if (index !== -1) {
            StaffMembers.splice(index, 1);
            form.setState({ StaffMembers });
        }
    }

    handleSelectStaff(propertyName, staff) {
        const { form } = this.props;

        if (!!staff) {
            delete staff['__typename'];

            form.setState({ [propertyName]: staff });
        }
    }

    handleAddStaffDetails() {
        const { form } = this.props;
        const AssignStaff = form.getState('AssignStaff') || [];
        AssignStaff.push({});
        form.setState({ AssignStaff });
    }

    handleMarkOrderAsComplete() {
        const { form } = this.props;

        form.setState({ Complete: true });
        form.save();
        this.setState({ showConfirmComplete: false });
    }
}

export default {
    id: 'Correspondence',
    label: 'Correspondence',
    component: withStyles({})(CorrespondenceTab),
    fragment: PlaqueOrder,
    onLoad: data => {
    },
    formatSaveData: (saveData, state) => {
        let localStaffMembers = [];

        if (saveData && saveData.StaffMembers) {
            state.StaffMembers.forEach(function(staffMember, index, object) {
                if (!!staffMember.new) {
                    if (staffMember.Staff && staffMember.Staff.ID) {
                        localStaffMembers.push({
                            StaffID: staffMember.Staff.ID,
                            Role: staffMember.Role,
                            Allocation: staffMember.Allocation
                        });
                    }
                } else if (staffMember.ID) {
                    localStaffMembers.push({
                        ID: staffMember.ID
                    });
                }
            });

            saveData.StaffMembers = localStaffMembers;
        }

        const localSignificantDocuments = [];
        if (saveData && saveData.SignificantDocuments) {
            state.SignificantDocuments.forEach(function(significantDocument, index, object) {
                if (!!significantDocument.ID) {
                    localSignificantDocuments.push({
                        ID: significantDocument.ID
                    });
                }
            });

            saveData.SignificantDocuments = localSignificantDocuments;
        }
    }
};

import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import TextField from '../../components/form/TextField';
import ColumnLayout from '../../components/layout/ColumnLayout';
import Grid from '../../components/layout/Grid';
import Button from '../../components/form/Button';
import CremationOrderFragment from '../../fragments/CremationOrder';
import NotesReadOnly from '../../components/form/NotesReadOnly';
import Table, { Cell, HeaderRow, Row } from '../../components/table/Table';
import EditIcon from '../../components/icon/EditIcon';
import DeleteIcon from '../../components/icon/DeleteIcon';
import ReflectionRoomBookingModal from './Modals/ReflectionRoomBookingModal';
import RadioGroup from '../../components/form/RadioGroup';
import StaffAutoComplete from '../../components/form/StaffAutoComplete';
import Inline, { inlineAlignment } from '../../components/layout/Inline';
import AlertBar from '../../components/form/AlertBar';
import UserCircle from '../../components/form/UserCircle';
import { joinDefined } from '../../utils/strings';
import { dateToString, niceTimeFromString } from '../../utils/date';
import { isNullOrUndefined } from '../../utils/objects';
import AlertModal from '../../components/modal/AlertModal';
import ReadonlyField from '../../components/form/ReadonlyField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

class CremationActionTab extends Component {
    state = {
        editEstimateDetails: true,
        editCR: true,
        showReflectionRoomBookingModal: false,
        showDeliveryReceivedByField: false,
        showCremationOperatorField: false,
        showDeleteReflectionRoomBookingAlertModal: false,
        showConfirmComplete: false
    };

    render() {
        const {
            showReflectionRoomBookingModal,
            showConfirmComplete,
            showDeleteReflectionRoomBookingAlertModal
        } = this.state;
        const { form } = this.props;

        return (
            <Fragment>
                <ColumnLayout>
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                </ColumnLayout>

                <ReflectionRoomBookingModal
                    open={showReflectionRoomBookingModal}
                    data={form.getField('ReflectionRoom')}
                    onClose={newItem => this.handleCloseReflectionRoomBookingModal(newItem)}
                />

                <AlertModal
                    variant="warning"
                    open={showDeleteReflectionRoomBookingAlertModal}
                    title="Prompt"
                    primaryAction="Delete"
                    onClickPrimaryAction={() => this.handleDeleteReflectionRoomBooking()}
                    secondaryAction="Cancel"
                    onClickSecondaryAction={() => this.handleCloseDeleteReflectionRoomBookingAlertModal()}
                    onClose={() => this.handleCloseDeleteReflectionRoomBookingAlertModal()}
                >
                    <p>You're about to delete this reflection room booking.</p>
                    <p>Are you sure?</p>
                </AlertModal>

                <AlertModal
                    variant="save"
                    open={showConfirmComplete}
                    title="Prompt"
                    primaryAction="Yes, Mark as Complete"
                    onClickPrimaryAction={() => this.handleMarkCremationAsComplete()}
                    secondaryAction="Actually, not just yet"
                    onClickSecondaryAction={() => this.setState({ showConfirmComplete: false })}
                    onClose={() => this.setState({ showConfirmComplete: false })}
                >
                    <p>You're about to mark this cremation as complete.</p>
                    <p>Are you sure?</p>
                </AlertModal>
            </Fragment>
        );
    }

    renderLeftColumn() {
        return (
            <Fragment>
                {this.renderDeliveryDate()}
                {this.renderReflectionRoom()}
            </Fragment>
        );
    }

    renderDeliveryDate() {
        const { form } = this.props;
        const { editEstimateDetails } = this.state;

        return (
            <Fragment>
                <Grid container bucket>
                    <Grid item>
                        <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                            <h4>Estimated Delivery</h4>
                            <IconButton
                                className="icon"
                                onClick={() => this.setState({ editEstimateDetails: !editEstimateDetails })}
                            >
                                <EditIcon />
                            </IconButton>
                        </Inline>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Estimated Delivery Date"
                            type="date"
                            form={form}
                            name="EstimatedDeliveryDate"
                            readOnly={editEstimateDetails}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Estimated Delivery Time"
                            type="time"
                            form={form}
                            name="EstimatedDeliveryTime"
                            readOnly={editEstimateDetails}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Estimated Deceased Weight"
                            type="number"
                            form={form}
                            name="EstimatedDeceasedWeight"
                            readOnly={editEstimateDetails}
                            InputProps={{ startAdornment: <InputAdornment position="start">KG</InputAdornment> }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Delivery by"
                            form={form}
                            name="EstimatedDeliveredBy"
                            readOnly={editEstimateDetails}
                        />
                    </Grid>
                </Grid>

                <Grid container bucket>
                    <Grid item>
                        <h4>Actual Delivery</h4>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField label="Actual Delivery Date" type="date" form={form} name="DeliveryDate" />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField label="Actual Delivery Time" type="time" form={form} name="DeliveryTime" />
                    </Grid>

                    <Grid item>
                        <Button
                            variant="link-orange"
                            onClick={() => this.insertDateAndTime('DeliveryDate', 'DeliveryTime')}
                        >
                            Add today's date and time
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Actual Deceased Weight" type="number" form={form} name="DeceasedWeight"
                            InputProps={{ startAdornment: <InputAdornment position="start">KG</InputAdornment> }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        {this.renderDeliveryReceivedByField()}
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    renderDeliveryReceivedByField() {
        const { form } = this.props;
        const { showDeliveryReceivedByField } = this.state;

        let deliveryReceivedBy = form.getState('DeliveryReceivedBy');

        if (!!deliveryReceivedBy && !showDeliveryReceivedByField) {
            let profileName = joinDefined([deliveryReceivedBy.FirstName, deliveryReceivedBy.Surname], ' ');
            let profileAbbreviation = '';

            if (profileName) {
                profileAbbreviation = profileName.match(/\b\w/g);
            }

            return (
                <ReadonlyField label="Delivery Received By">
                    <UserCircle
                        user={profileName}
                        abbreviation={profileAbbreviation}
                        onDelete={() =>
                            this.handleDeleteStaff('DeliveryReceivedBy') &&
                            this.setState({ showDeliveryReceivedByField: true })
                        }
                    />
                </ReadonlyField>
            );
        }

        return (
            <StaffAutoComplete
                label="Delivery Received By"
                placeholder="Search for a staff name here"
                selectProps={{ multiple: false }}
                onSelect={(_, user) => this.handleSelectStaff('DeliveryReceivedBy', user)}
                form={form}
                name="DeliveryReceivedBy"
            />
        );
    }

    renderReflectionRoom() {
        const { form } = this.props;
        const showBookingTable = form.getField('ReflectionRoomRequired') === 'Required';
        const ReflectionRoom = form.getField('ReflectionRoom');
        const HasReflectionRoom = ReflectionRoom && ReflectionRoom.Date;
        const formattedNames =
            HasReflectionRoom && ReflectionRoom.AttendeeNames && ReflectionRoom.AttendeeNames.replace(/\n/g, ', ');

        const dateStamp = moment(form.getField('CremationDate') || '').format('YYYYMMDD');

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Is a reflection room and view required?</h4>
                    <RadioGroup
                        options={[
                            { label: 'Yes, viewing is required', value: 'Required' },
                            { label: 'No, viewing is not required', value: 'NotRequired' },
                            { label: 'Unsure', value: '' }
                        ]}
                        name="ReflectionRoomRequired"
                        form={form}
                    />
                </Grid>

                {showBookingTable && (
                    <Fragment>
                        <Grid item>
                            <h5>{'Reflection Room booking: ' + (HasReflectionRoom ? '' : 'None')}</h5>
                        </Grid>

                        {HasReflectionRoom && (
                            <Grid item>
                                <Table>
                                    <HeaderRow pad>
                                        <Cell colSpan={1}>Date</Cell>
                                        <Cell colSpan={1}>Time</Cell>
                                        <Cell colSpan={1}>Attendees</Cell>
                                        <Cell colSpan={1}>Edit</Cell>
                                        <Cell colSpan={1}>Delete</Cell>
                                    </HeaderRow>
                                    <Row pad>
                                        <Cell dataLabel="Date">
                                            <Button
                                                variant="link-orange"
                                                onClick={() => this.handleShowReflectionRoomBookingModal()}
                                            >
                                                {dateToString(ReflectionRoom.Date)}
                                            </Button>
                                        </Cell>
                                        <Cell dataLabel="Time">
                                            {niceTimeFromString(ReflectionRoom.Start)}
                                            {' - '}
                                            {niceTimeFromString(ReflectionRoom.End)}
                                        </Cell>
                                        <Cell dataLabel="Attendees">{formattedNames}</Cell>
                                        <Cell dataLabel="Edit">
                                            <IconButton
                                                className="icon"
                                                onClick={() => this.handleShowReflectionRoomBookingModal()}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Cell>
                                        <Cell dataLabel="Delete">
                                            <IconButton
                                                // onClick={() => this.handleDeleteReflectionRoomBooking()}
                                                onClick={() => this.handleShowDeleteReflectionRoomBookingAlertModal()}
                                                className="icon"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Cell>
                                    </Row>
                                </Table>
                            </Grid>
                        )}
                        <Grid item>
                            <Inline>
                                {!HasReflectionRoom && (
                                    <Button
                                        variant="primary"
                                        onClick={() => this.handleShowReflectionRoomBookingModal()}
                                    >
                                        Add Room Booking
                                    </Button>
                                )}
                                <Button variant="secondary" href={'/calendar/' + dateStamp} target="_blank">
                                    View Calendar
                                </Button>
                            </Inline>
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        );
    }

    handleDeleteReflectionRoomBooking() {
        const { form } = this.props;

        form.setState({
            ReflectionRoomID: 0,
            ReflectionRoom: {
                Confirmed: false,
                Start: null,
                End: null,
                Date: null,
                Time: null,
                Duration: null,
                NumberOfPeople: null,
                AuthorisedContact: null,
                Applicant: null,
                AttendeeNames: null
            }
        });
    }

    renderRightColumn() {
        const { form } = this.props;
        const query = gql`
            query ReadOneCremationReady($id: ID!) {
                readOneCremationOrder(ID: $id){
                    ID
                    ReadyForCremation
                }
            }
        `;
        return (
            <Query query={query} variables={{ id: form.getState('ID') }} fetchPolicy='network-only'>
                {({ data }) => {
                    const readOneCremationOrder = data && data.readOneCremationOrder;
                    const { ReadyForCremation } = readOneCremationOrder || {};
                    return (<>
                        {this.getAdditionalActions(ReadyForCremation)}
                        {this.renderCremationOperator()}
                        {this.renderInsertionDate(ReadyForCremation)}
                        {this.renderCRNumber()}
                        {this.renderCremationEndDate(ReadyForCremation)}
                        {this.renderCremationActionNotes()}
                    </>);
                }}
            </Query>
        );
    }

    renderInsertionDate(readyForCremation) {
        const { form } = this.props;

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Insertion</h4>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Estimated Insertion Date" type="date" form={form} name="EstimatedInsertionDate" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <p className="readonly">
                        <Hidden smDown>
                            <br />
                        </Hidden>
                        <span className="value">
                            Requested date is {dateToString(form.getField('CremationDate')) || 'not set'}.
                        </span>
                    </p>
                </Grid>

                {(readyForCremation && (
                    <Fragment>
                        <Grid item xs={12} sm={6}>
                            <TextField label="Actual Insertion Date" type="date" form={form} name="InsertionDate" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField label="Actual Insertion Time" type="time" form={form} name="InsertionTime" />
                        </Grid>

                        <Grid item>
                            <Button
                                variant="link-orange"
                                onClick={() => this.insertDateAndTime('InsertionDate', 'InsertionTime')}
                            >
                                Add today's date and time
                            </Button>
                        </Grid>
                    </Fragment>
                )) || (
                    <Grid item>
                        <p>Order is not marked as Cremation Ready.</p>
                    </Grid>
                )}
            </Grid>
        );
    }

    renderCRNumber() {
        const { form } = this.props;
        const { editCR } = this.state;
        return (
            <Grid container bucket>
                <Grid item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                        <h4>Cremation Number</h4>

                        <IconButton className="icon" onClick={() => this.setState({ editCR: !editCR })}>
                            <EditIcon />
                        </IconButton>
                    </Inline>
                </Grid>
                <Grid item>
                    {((!editCR || !!form.getField('CremationNumber')) && (
                        <TextField
                            label="CR Number"
                            placeholder="Update CR Number..."
                            type="number"
                            form={form}
                            name="CremationNumber"
                            readOnly={!!editCR}
                        />
                    )) || <ReadonlyField label="CR Number">Not yet generated</ReadonlyField>}
                </Grid>
            </Grid>
        );
    }

    renderCremationEndDate(readyForCremation) {
        const { form } = this.props;

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Action Ended</h4>
                </Grid>

                {(readyForCremation && (
                    <Fragment>
                        <Grid item xs={12} sm={6}>
                            <TextField label="End Date" type="date" form={form} name="CremationEndDate" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField label="End Time" type="time" form={form} name="CremationEndTime" />
                        </Grid>

                        <Grid item>
                            <Button
                                variant="link-orange"
                                onClick={() => this.insertDateAndTime('CremationEndDate', 'CremationEndTime')}
                            >
                                Add today's date and time
                            </Button>
                        </Grid>

                        {this.renderCremationCompleteField(readyForCremation)}
                    </Fragment>
                )) || (
                    <Grid item>
                        <p>Order is not marked as Cremation Ready.</p>
                    </Grid>
                )}
            </Grid>
        );
    }

    renderCremationOperator() {
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Cremation Operator</h4>
                </Grid>

                <Grid item>{this.renderCremationOperatorField()}</Grid>
            </Grid>
        );
    }

    renderCremationOperatorField() {
        const { form } = this.props;
        const { showCremationOperatorField } = this.state;

        let cremationOperator = form.getState('CremationOperator');

        if (!!cremationOperator && !showCremationOperatorField) {
            let profileName = joinDefined([cremationOperator.FirstName, cremationOperator.Surname], ' ');
            let profileAbbreviation = '';

            if (profileName) {
                profileAbbreviation = profileName.match(/\b\w/g);
            }

            return (
                <UserCircle
                    user={profileName}
                    abbreviation={profileAbbreviation}
                    onDelete={() =>
                        this.handleDeleteStaff('CremationOperator') &&
                        this.setState({ showCremationOperatorField: true })
                    }
                />
            );
        }

        return (
            <StaffAutoComplete
                placeholder="Search for a staff name here"
                selectProps={{ multiple: false }}
                onSelect={(_, user) => this.handleSelectStaff('CremationOperator', user)}
                form={form}
                name="CremationOperator"
            />
        );
    }

    renderCremationActionNotes() {
        const { form } = this.props;

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Cremation Action Notes</h4>
                </Grid>

                <Grid item>
                    <TextField
                        placeholder="Notes are written here..."
                        multiline
                        name="CremationActionNotes"
                        form={form}
                    />
                </Grid>

                {form.getField('OldCremationActionNotes') && (
                    <Grid item>
                        <h4>Note History</h4>
                    </Grid>
                )}

                {form.getField('OldCremationActionNotes') && (
                    <Grid item>
                        <NotesReadOnly name="OldCremationActionNotes" form={form} />
                    </Grid>
                )}
            </Grid>
        );
    }

    renderCremationCompleteField(readyForCremation) {
        const { form } = this.props;

        if (form.getState('CremationComplete')) {
            return (
                <Grid item>
                    <Inline>
                        <AlertBar variant="success">This cremation has been marked as complete</AlertBar>
                    </Inline>
                </Grid>
            );
        }

        return (
            <Grid item>
                <Button
                    disabled={form.isDirty || form.getField('CremationPending') || !readyForCremation}
                    variant="primary"
                    onClick={() => this.setState({ showConfirmComplete: true })}
                >
                    Mark as Cremation Completed...
                </Button>
            </Grid>
        );
    }

    getAdditionalActions(readyForCremation) {
        const { form } = this.props;
        const missing = form.getField('HasMissingCertificates');
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Action Start</h4>
                </Grid>
                {(!!missing && !readyForCremation &&
                    <Grid item>
                        <p>
                            Cannot start until marked as Cremation Ready.<br/>
                            Please check the Correspondence tab for details.
                        </p>
                    </Grid>
                ) || (
                    <Grid item>
                        <p>
                            Order is {!readyForCremation && 'not'} marked as Cremation Ready.
                        </p>
                    </Grid>
                )}
            </Grid>
        );
    }

    handleMarkCremationAsComplete() {
        const { form } = this.props;

        form.setState({ CremationComplete: true });
        form.save();
        this.setState({ showConfirmComplete: false });
    }

    handleShowReflectionRoomBookingModal() {
        this.setState({ showReflectionRoomBookingModal: true });
    }

    handleCloseReflectionRoomBookingModal(newItem) {
        const { form } = this.props;
        this.setState({ showReflectionRoomBookingModal: false });
        if (newItem) form.setField({ ReflectionRoom: newItem });
    }

    insertDateAndTime(dateFieldName, timeFieldName) {
        const { form } = this.props;

        form.setState({
            [dateFieldName]: moment().format('YYYY-MM-DD'),
            [timeFieldName]: moment().format('HH:mm')
        });
    }

    handleSelectStaff(propertyName, staff) {
        const { form } = this.props;

        if (!!staff) {
            delete staff['__typename'];

            this.setState({ showCremationOperatorField: false });

            form.setState({ [propertyName]: staff });
        }
    }

    handleDeleteStaff(propertyName) {
        const { form } = this.props;

        form.setState({
            [propertyName + 'ID']: null,
            [propertyName]: null
        });
    }

    handleShowDeleteReflectionRoomBookingAlertModal() {
        this.setState({ showDeleteReflectionRoomBookingAlertModal: true });
    }

    handleCloseDeleteReflectionRoomBookingAlertModal() {
        this.setState({ showDeleteReflectionRoomBookingAlertModal: false });
    }
}

export default {
    id: 'CremationAction',
    label: 'Cremation Action',
    component: withStyles({})(CremationActionTab),
    fragment: CremationOrderFragment,
    onLoad: data => {
        // create a stub to detect new notes on save!
        if (!!data.CremationActionNotes) {
            data.OldCremationActionNotes = '' + (data.CremationActionNotes || '');
        }
        delete data.CremationActionNotes;
    },
    formatSaveData: (saveData, state) => {
        if (saveData.CremationOperator === null) {
            saveData.CremationOperatorID = null;
            delete saveData['CremationOperator'];
        }
        if (saveData.DeliveryReceivedBy === null) {
            saveData.DeliveryReceivedByID = null;
            delete saveData['DeliveryReceivedBy'];
        }
        if (!state.CremationNumber) {
            saveData.CremationNumber = 0; // can't be empty.
        }
        const reflectionRoom = state.ReflectionRoom;
        if (state.ReflectionRoomRequired !== 'Required' && reflectionRoom) {
            if (reflectionRoom && reflectionRoom.ID !== '0' && !isNullOrUndefined(reflectionRoom.ID)) {
                saveData.ReflectionRoom = state.ReflectionRoom;
                saveData.ReflectionRoom.Start = null;
                saveData.ReflectionRoom.End = null;
                saveData.ReflectionRoom.Date = null;
                saveData.ReflectionRoom.Time = null;
                saveData.ReflectionRoom.Duration = null;
                saveData.ReflectionRoom.AttendeeNames = null;
                saveData.ReflectionRoom.AuthorisedContact = null;
                saveData.ReflectionRoom.Applicant = null;
            }
        }
    }
};
